<template>
  <div class="tournament-container">
    <div class="container tournament-info-breadcrumb-container">
      <div class="tournament-info-breadcrumb">
        <div class="mt-1 row text-center">
          <div class="col-12 text-start">
            <router-link :to="{ name: 'Home' }" class="ashen-link">{{ $t("home.default.pageTitle") }}</router-link> <i class="fa fa-angle-right"></i> <span>{{ $t("home.tournamentDetail.tournamentInfo") }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container tournament-info-next-prev-container">
      <div class="tournament-info-next-prev">
        <div class="mt-1 row text-center">
          <div class="col text-start text-clamp-1">
            <router-link :to="linkPrevDate" class="ashen-link"><i v-if="prevTournamentName" class="fa fa-angle-left"></i><span class="ml-3 mx-2">{{ prevTournamentName }}</span></router-link>
          </div>
          <div class="col text-end d-flex next-tournament">
            <router-link :to="linkNextDate" class="ashen-link text-clamp-1"><span class="mr-3 mx-1">{{ nextTournamentName }}</span></router-link>
            <i v-if="nextTournamentName" class="fa fa-angle-right m-t-5"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="container tournament-info-container">
      <div class="card">
        <div class="card-header">
          <img src="../assets/images/racket-gray.svg">
          <span class="mx-2 tournament-title">{{ tournamentName }}</span>
          <span class="tournament-status" v-html="showTournamentStatus(obj.status)"></span>
        </div>
        <div class="card-body tournament-card-body">
          <div class="row  update-info">
            <div class="col-md-12">
              <label for="inputPeriod" class="mt-3">{{ $t("home.tournamentDetail.inputPeriod") }}</label>
              <input type="text" id="inputPeriod" class="form-control" disabled  name="inputPeriod" v-model="inputDatePlaceHolder">
              <label for="inpuHostPrefecture" class="mt-3">{{ $t("home.tournamentDetail.hostPrefecture") }}</label>
              <input type="text" id="inpuHostPrefecture" class="form-control" disabled  name="inpuHostPrefecture" v-model="hostPrefecture">
              <template v-if="hostOrganization">
                <label for="inputOrganizer" class="mt-3">{{ $t("home.tournamentDetail.organizer") }}</label>
                <textarea id="inputOrganizer" class="form-control" disabled  name="inputOrganizer" v-bind:value="hostOrganization"></textarea>
              </template>
              <template v-if="inquiryPhoneNo != null">
                <label for="inputInquiryPhone" class="mt-3">{{ $t("home.tournamentDetail.inquiryPhoneNumber") }}</label>
                <input type="text" id="inputInquiryPhone" class="form-control" disabled  name="inputInquiryPhone" v-model="inquiryPhoneNo">
              </template>
              <template v-if="inquiryEmail != null">
                <label for="inputInquiryEmail" class="mt-3">{{ $t("home.tournamentDetail.inquiryEmail") }}</label>
                <input type="email" id="inputInquiryEmail" class="form-control" disabled  name="inputInquiryEmail" v-model="inquiryEmail">
              </template>

              <label for="inputRemindMail" class="mt-3">{{ $t("home.tournamentDetail.remindMail") }}</label>
              <input id="inputRemindMail" class="form-control" disabled  name="inputInquiryEmail" v-model="remindMail">
            </div>

            <div class="row m-t-20">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
                <template v-if="remindMailValue !== null">
                  <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                          v-on:click="doChange">
                    <template v-if="remindMailValue === true">
                      {{ $t("home.tournamentDetail.turnOff") }}
                    </template>
                    <template v-else>
                      {{ $t("home.tournamentDetail.turnOn") }}
                    </template>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Common from "../assets/jsipclient/common";
import { mapGetters, mapActions } from "vuex";
import {TournamentDetail, TournamentItems, SetRemindMail} from "../assets/jsipclient/index";
import Helper from "../assets/jsipclient/helper";
import Vue from 'vue'

export default {
  name: "TournamentInfo",
  data(){
    return {
      isHome : true,
      errors: [],
      obj: {},
      tournamentId: null,
      tournamentName: null,
      prevTournamentName: null,
      nextTournamentName: null,
      prevTournamentId: null,
      nextTournamentId: null,
      startAt: null,
      endAt: null,
      inputStartAt: null,
      inputEndAt: null,
      statusText: null,
      remindMail: null,
      remindMailValue: null,
      hostPrefecture: null,
      hostOrganization: null,
      inquiryPhoneNo: null,
      inquiryEmail: null,
      tournamentAdminEmail: null,
    }
  },
  computed: {
    ...mapGetters(['config']),
    inputDatePlaceHolder() {
      return moment(this.inputStartAt).format("YYYY/MM/DD") + " 〜 " + moment(this.inputEndAt).format("YYYY/MM/DD")
    },
    linkPrevDate() {
      return {
        name: "TournamentInfo",
        params: {
          id: this.prevTournamentId
        }
      }
    },

    linkNextDate() {
      return {
        name: "TournamentInfo",
        params: {
          id: this.nextTournamentId
        }
      }
    },
  },
  async mounted () {
    window.scrollTo({top: 0, behavior: "auto"})
    await this.doGet(this.$route.params.id)
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.doGet(this.$route.params.id)
    }
  },
  methods: {
    async doGet (id) {
      this.errors = [];

      try {
        const user_id = this.$store.getters.config.userId
        const token = this.$store.getters.config.token
        const tournamentId = id

        const api = Common.createUserApi(token)

        const jsonObject = await api.usersUserIdTournamentsTournamentIdGetAsync(user_id, tournamentId)
        const result = TournamentDetail.createFromJson(jsonObject)
        this.obj = result
        this.tournamentId = result.tournamentId;
        this.tournamentName = result.tournamentName;
        this.startAt = Common.dateFormatConvert(result.startAt);
        this.endAt = Common.dateFormatConvert(result.endAt)
        this.inputStartAt = Common.dateFormatConvert(result.inputStartAt)
        this.inputEndAt = Common.dateFormatConvert(result.inputEndAt)
        this.userRole = result.userRole
        this.statusText = Common.getTournamentStatus(result.status)
        if (result.remindMail === false) {
          this.remindMail = this.$t("home.tournamentDetail.none")
          this.remindMailValue = false
        } else {
          this.remindMail = this.$t("home.tournamentDetail.yes")
          this.remindMailValue = true
        }
        this.hostPrefecture = result.hostPrefecture
        this.hostOrganization = result.hostOrganization
        this.inquiryPhoneNo = result.inquiryPhoneNo
        this.inquiryEmail = result.inquiryEmail
        this.tournamentAdminEmail = result.tournamentAdminEmail
        this.prevTournamentName = result.prevTournamentName
        this.nextTournamentName = result.nextTournamentName
        this.prevTournamentId = result.prevTournamentId
        this.nextTournamentId = result.nextTournamentId
      } catch (error) {
        if (error.response) {
          const res = error.response.data
          if (res.validationErrors !== null) {
            res.validationErrors.forEach(e => this.errors[e.name] = e.message)
          } else if (res.result === "ERR_EXPIRE_TOKEN") {
            // logout
          } else {
            // Other error
          }
        }
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    showTournamentStatus(tournamentStatus, extraCss = '') {
      var statusText = ""
      switch (tournamentStatus) {
        case 1:
          statusText = this.$t("home.default.comingSoon")
          break;
        case 2:
          statusText = this.$t("home.default.duringPeriod")
          break;
        case 3:
          statusText = this.$t("home.default.end")
          break;
      }
      return Helper.showTournamentStatus(tournamentStatus, extraCss, statusText)
    },
    tournamentInfo(e){
      e.preventDefault()
      this.isHome = !this.isHome
    },
    createSetRemindMail(result) {
      let mail = new SetRemindMail()
      mail.remindMail = result
      return mail;
    },
    async doChange() {
      this.errors = [];
      const user_id = this.config.userId
      const token = this.config.token
      const tournamentId = this.tournamentId
      try {
        const api = Common.createUserApi(token)
        const setRemindMail = this.createSetRemindMail(!this.remindMailValue)
        const jsonObject = await api.usersUserIdTournamentsTournamentIdRemindMailPostAsync(user_id, tournamentId, setRemindMail)

        const message = this.$t("home.tournamentDetail.toastSuccessChangeRemindMail")
        Common.showToast(message)
        this.doGet(this.tournamentId)
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  }
}

</script>

<style scoped>

.tournament-container {
  margin-bottom: 100px;
}
label[for="inputPeriod"], label[for="inputInquiryPhoneNumber"]{
  margin-top: 0px;
}

label {
  color: #333333;
  font-size: 16px;
  font-weight: 450;
  padding-bottom: 7px;
}

.card-header {
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 31px;
  color: #333333;
  padding-left: 20px;
  padding-right: 20px;
}

.card svg, h6 > i {
  margin-right: 5px;
}

.card-body {
  padding: 20px 30px;
}

.card-body > p {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #333333;
}

.card-body > p:last-child {
  margin-bottom: 0px;
}

table {
  margin-bottom: 0;
}

.tournament-card-body {
  padding: 50px 0;
  padding-top:30px;
}

.tournament-card-body td {
  padding-right: 30px;
  padding-left: 30px;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #333333;
  vertical-align: middle;
}

.update-info {
  margin-left: 0;
  margin-right: 0;
  width: 80%;
  margin: auto;
}

.bg-time-duration {
  margin-left: 10px;
  padding: 0.30em 0.60em;
}

input {
  background-color: #EFF2F7;
  height: 48px;
}

textarea {
  height: 62px !important;
  resize: none;
}


.tournament-info-breadcrumb {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 17px;
  position: relative;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #D3D8EA;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.fa-angle-right, .fa-angle-left {
  color: #888888;
  padding-left: 6px;
  padding-right: 6px;
}

.tournament-info-next-prev {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.tournament-info-next-prev a {
  font-weight: bold;
  color: #333333;
}

li{
  list-style-type: none;
}

.tournament-status {
  margin-left: 20px;
}

@media only screen and (max-width: 991px) {
  .tournament-status {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 768px) {

  .tournament-info-next-prev a {
    font-size: 13px;
  }
  .next-tournament{
    margin-top: 2px;
  }


  .update-info {
    width: 90%;
  }

  .update-info .col-md-6:last-child {
    padding-top: 10px;
  }

  .host-and-inquiry .col-md-6:first-child {
    padding-top: 10px;
  }


}

@media only screen and (max-width: 576px) {

  .tournament-container[data-v-8a0e191e] {
    top: 0;
  }

  .next-tournament{
    margin-top: 3px;
  }

  .tournament-title{
    display: inline-block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 176px !important;
    margin-bottom: -9px !important;
  }

  .tournament-info-breadcrumb {
    border-bottom: 0;
  }

  .tournament-info-breadcrumb-container a, .tournament-info-breadcrumb-container i {
    display: none;
  }

  .tournament-info-breadcrumb-container span {
    font-size: 24px;
    font-weight: bold;
  }

  .tournament-info-next-prev-container {
    border-top: 1px solid #D3D8EA;
  }

  .tournament-info-next-prev {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tournament-info-container {
    padding-left: 0;
    padding-right: 0;
  }

  .card {
    border-radius: 0;
    border-bottom: 0;
  }

  .tournament-status {
    margin-left: 14px;
  }

}

</style>
